<script>
import sal from 'sal.js';
import "sal.js/dist/sal.css";
import { onMount } from "svelte";

export let title;
export let skills;
export let description;
export let img;
export let i;
export let link;


onMount(() => {
    sal({
        threshold: 0.1
    })
});

</script>

<style type="text/scss">:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family: "Open Sans", sans-serif;
  --font-family-fatface: "Abril Fatface", cursive;
  --font-family-josefin: "Josefin Sans", sans-serif;
  --font-family-sans-pro: "Source Sans Pro", sans-serif;
  --font-family-merriweather: "Merriweather", serif;
  --font-family-inter: "Inter", sans-serif;
  --font-light: 300;
  --font-regular: 400;
  --font-bold: 700;
  --font-size-xxxl: 8rem;
  --font-size-xxl: 5rem;
  --font-size-xl: 1.75rem;
  --font-size-l: 24px;
  --font-size-m: 18px;
  --font-size-s: 14px;
  --font-size-xs: 10px;
  --spacer: var(--font-size-m);
  --border-radius: 7px;
  --grid-gap: 30px;
  --line-height-short: 1.15;
  --line-height-medium: 2.5;
  --dark-4: rgb(0, 0, 0);
  --dark-3: #2d2d2d;
  --dark-2: #2c2c2b;
  --dark-1: #424242;
  --white: rgb(255, 255, 255);
  --light-3: #d8d8d8;
  --light-2: #afaeae;
  --light-1: #818181;
  --je-red: #fd5c5a;
  --je-gold: #fecb75;
  --je-tan: #fcf5e2;
  --je-light-bg: #8bdada;
  --je-bg: #4cc2c0;
  --skin: #ffd1c2;
  --skin2: #feb59e;
  --hair: #524747;
  --shirt: #8186ff;
  --pants: #4cc2c0;
  --color-text-primary: var(--dark-4);
  --color-text-inverted: var(--white);
  --color-background: var(--je-tan);
  --color-card: var(--light-3);
  --box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.158);
  --z-1: 1;
  --z-2: 100;
  --z-3: 500;
  --z-4: 900;
  --z-5: 1000;
  --border-size-l: 1.5rem;
  --border-size-m: 0.75rem;
  --border-size-s: 0.5rem;
  --border-size-xs: 0.25rem; }

.project {
  position: relative;
  width: 100%;
  padding: 5vh 0;
  margin: 0 auto;
  flex-wrap: wrap;
  overflow: hidden; }

.title {
  position: relative;
  display: inline-block;
  border-bottom: solid var(--border-size-m) var(--je-red); }

.child {
  flex: 1;
  position: relative;
  min-width: 300px; }
  @media (min-width: 769px) {
    .child {
      margin: 2rem;
      min-width: 400px; } }

.img {
  height: auto;
  transition: 1s;
  width: 100%; }

.link-text {
  display: inline-block;
  color: white;
  transition: 0.5s; }

.link {
  text-decoration: none;
  background: var(--je-red);
  padding: 1rem 1.5rem;
  border-radius: 5px;
  transition: 0.2s;
  color: white; }
  .link:hover {
    background: var(--je-gold); }
  .link:hover .link-text {
    color: var(--color-text-primary); }

.text {
  font-size: var(--font-size-m);
  font-weight: var(--font-regular); }
  @media (min-width: 769px) {
    .text {
      font-size: var(--font-size-l); } }</style>

<div class="project d-flex" data-sal="slide-up" data-sal-delay="0" data-sal-duration="500" data-sal-easing="ease-out-bounce">
    <div class="child child-left mb-4">
        <h2 class="mb-1 mt-0 pb-2 title">{title}</h2>
        <div>
            <p class="text pb-1">{description}</p>
            <a class="link mb-4" href="{link}" target="_blank"><p class="link-text mb-0">Visit</p></a>
        </div>
    </div>
    <div class="child child-right">
        <div>
            <img class="img" src={img}/>
        </div>
        <div>
            {#each skills as skill, i}
            <p class="skill skill{i}">{skill}</p>
            {/each}
        </div>
    </div>
</div>
