<script>
    import { fly, scale } from 'svelte/transition';
    import { quadOut } from 'svelte/easing';
    import Hamburger from 'svelte-hamburgers';
    import { link } from 'svelte-spa-router'

    export let open;
    const links = [
        {
            name: 'Home',
            path: '/'
        },
        // {
        //     name: 'Web Design',
        //     path: '/work'
        // },
        {
            name: 'Contact',
            path: '/contact'
        }
    ]
</script>

<svelte:head>
	<!-- Import base css -->
	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/svelte-hamburgers@3/dist/css/base.css" />
	<!-- Import spin css (spin is default type) -->
	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/svelte-hamburgers@3/dist/css/types/spin.css" />
</svelte:head>

<div class="hamburger">
    <Hamburger bind:open --color="black"/>
</div>
<nav class:nav-active={open} class="nav">
    {#if open}
        <div class="links mt-6 pt-6">
            {#each links as {name, path}, i}
                <p in:fly={{ y: -15, delay: 50 * i }}>
                    <a class="link" on:click={()=> {open = false}} use:link href={path}>{name}</a>
                </p>
            {/each}
        </div>
    {/if}
</nav>

<style type="text/scss">:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family: "Open Sans", sans-serif;
  --font-family-fatface: "Abril Fatface", cursive;
  --font-family-josefin: "Josefin Sans", sans-serif;
  --font-family-sans-pro: "Source Sans Pro", sans-serif;
  --font-family-merriweather: "Merriweather", serif;
  --font-family-inter: "Inter", sans-serif;
  --font-light: 300;
  --font-regular: 400;
  --font-bold: 700;
  --font-size-xxxl: 8rem;
  --font-size-xxl: 5rem;
  --font-size-xl: 1.75rem;
  --font-size-l: 24px;
  --font-size-m: 18px;
  --font-size-s: 14px;
  --font-size-xs: 10px;
  --spacer: var(--font-size-m);
  --border-radius: 7px;
  --grid-gap: 30px;
  --line-height-short: 1.15;
  --line-height-medium: 2.5;
  --dark-4: rgb(0, 0, 0);
  --dark-3: #2d2d2d;
  --dark-2: #2c2c2b;
  --dark-1: #424242;
  --white: rgb(255, 255, 255);
  --light-3: #d8d8d8;
  --light-2: #afaeae;
  --light-1: #818181;
  --je-red: #fd5c5a;
  --je-gold: #fecb75;
  --je-tan: #fcf5e2;
  --je-light-bg: #8bdada;
  --je-bg: #4cc2c0;
  --skin: #ffd1c2;
  --skin2: #feb59e;
  --hair: #524747;
  --shirt: #8186ff;
  --pants: #4cc2c0;
  --color-text-primary: var(--dark-4);
  --color-text-inverted: var(--white);
  --color-background: var(--je-tan);
  --color-card: var(--light-3);
  --box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.158);
  --z-1: 1;
  --z-2: 100;
  --z-3: 500;
  --z-4: 900;
  --z-5: 1000;
  --border-size-l: 1.5rem;
  --border-size-m: 0.75rem;
  --border-size-s: 0.5rem;
  --border-size-xs: 0.25rem; }

.nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 0;
  width: 100vw;
  z-index: 0; }

.nav-active {
  background-color: var(--je-tan);
  height: 100vh;
  width: 100vw;
  z-index: 1000; }

.hamburger {
  position: fixed;
  text-align: right;
  width: 100%;
  z-index: 1001; }

.links {
  text-align: center; }

a {
  font-size: var(--font-size-xl);
  font-weight: var(--font-bold);
  color: black;
  text-decoration: none;
  margin-left: 20px;
  padding-bottom: 2px; }

@media (min-width: 769px) {
  a {
    font-size: var(--font-size-xxl); } }

a:hover {
  border-bottom: solid 10px var(--je-red);
  transition: 0.2s; }

p {
  cursor: pointer;
  width: max-content;
  margin: 1rem auto; }</style>
