<script>
import James from "james";

let limbs = {    
    lArm: {        
        before: 15,        
        after: 30,
        time: 600
    },
    lFore: {
        before: -120,
        after: 10,
        time: 1300
    },
    rArm: {
        before: -20,
        after: -160,
        time: 1400
    },
    rFore: {
        before: 120,
        after: -5,
        time: 800
    },
    lLeg: {
        before: -10,
        after: 5,
        time: 1000
    },
    lShin: {
        before: 0,
        after: 0,
        time: 1000
    },
    rLeg: {
        before: 0,
        after: -5,
        time: 1000
    },
    rShin: {
        before: 35,
        after: 0,
        time: 1000
    }, 
}
let body = {
    before: {
        deg: 0,
        X: 0,
        Y: 0,
    },
    after: {
        deg: -5,
        X: 0,
        Y: 0,
    },
    time: 1000,
}

// let limbs = {
//     lArm: {
//         before: 15,
//         after: 120,
//         time: 600
//     },
//     lFore: {
//         before: -120,
//         after: 70,
//         time: 1300
//     },
//     rArm: {
//         before: -20,
//         after: -25,
//         time: 1000
//     },
//     rFore: {
//         before: 120,
//         after: 5,
//         time: 800
//     },
//     lLeg: {
//         before: -10,
//         after: 2,
//         time: 1000
//     },
//     lShin: {
//         before: 0,
//         after: 0,
//         time: 1000
//     },
//     rLeg: {
//         before: 0,
//         after: 0,
//         time: 1000
//     },
//     rShin: {
//         before: 35,
//         after: -5,
//         time: 1000
//     }, 
// }

</script>

<style type="text/scss">:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family: "Open Sans", sans-serif;
  --font-family-fatface: "Abril Fatface", cursive;
  --font-family-josefin: "Josefin Sans", sans-serif;
  --font-family-sans-pro: "Source Sans Pro", sans-serif;
  --font-family-merriweather: "Merriweather", serif;
  --font-family-inter: "Inter", sans-serif;
  --font-light: 300;
  --font-regular: 400;
  --font-bold: 700;
  --font-size-xxxl: 8rem;
  --font-size-xxl: 5rem;
  --font-size-xl: 1.75rem;
  --font-size-l: 24px;
  --font-size-m: 18px;
  --font-size-s: 14px;
  --font-size-xs: 10px;
  --spacer: var(--font-size-m);
  --border-radius: 7px;
  --grid-gap: 30px;
  --line-height-short: 1.15;
  --line-height-medium: 2.5;
  --dark-4: rgb(0, 0, 0);
  --dark-3: #2d2d2d;
  --dark-2: #2c2c2b;
  --dark-1: #424242;
  --white: rgb(255, 255, 255);
  --light-3: #d8d8d8;
  --light-2: #afaeae;
  --light-1: #818181;
  --je-red: #fd5c5a;
  --je-gold: #fecb75;
  --je-tan: #fcf5e2;
  --je-light-bg: #8bdada;
  --je-bg: #4cc2c0;
  --skin: #ffd1c2;
  --skin2: #feb59e;
  --hair: #524747;
  --shirt: #8186ff;
  --pants: #4cc2c0;
  --color-text-primary: var(--dark-4);
  --color-text-inverted: var(--white);
  --color-background: var(--je-tan);
  --color-card: var(--light-3);
  --box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.158);
  --z-1: 1;
  --z-2: 100;
  --z-3: 500;
  --z-4: 900;
  --z-5: 1000;
  --border-size-l: 1.5rem;
  --border-size-m: 0.75rem;
  --border-size-s: 0.5rem;
  --border-size-xs: 0.25rem; }

.intro {
  position: relative;
  z-index: 1; }

.name {
  margin: 100px auto;
  text-align: center; }

.name h1 {
  display: inline-block;
  padding-bottom: 15px;
  margin: 0;
  border-bottom: solid var(--border-size-m) var(--je-red); }

.about {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media (min-width: 769px) {
    .about {
      justify-content: center; } }

.about .text {
  border: solid var(--border-size-s) var(--je-red);
  padding: 40px;
  width: 100%; }
  .about .text p {
    font-size: var(--font-size-m); }
  @media (min-width: 769px) {
    .about .text {
      max-width: 600px; }
      .about .text p {
        font-size: var(--font-size-l); } }

.email {
  transition: 0.2s;
  background-color: var(--je-red);
  border-radius: 5px; }
  .email:hover {
    background-color: var(--je-gold);
    cursor: pointer; }

.img-email {
  height: 40px;
  width: auto; }

.img {
  height: 30px;
  width: auto; }

a {
  text-decoration: none;
  color: var(--je-primary); }</style>

<div class="intro">
    <div class="name">
        <h1>James Evans</h1>
        <h3>Front End Developer</h3>
    </div>
    <div class="about">
        <div class="text">
            <p>Hey there! I’m James, a Front End Developer from Seattle. I’m a self starter with a drive to learn the newest tech I can get my hands on. I have experience in large scale companies like Amazon, but also in start ups and the freelance world. Let’s chat! </p>
            <div class="d-flex align-items-center">
                <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=evans.james00@gmail.com" target="_blank">
                    <img class="img-email" src="images/iconmonstr-email.svg"/>
                </a>
                <a href="https://www.linkedin.com/in/james-evans-46020314a/" target="_blank">
                    <img class="img ml-2" src="images/iconmonstr-linkedin-1.svg"/>
                </a>
            </div>
        </div>
        <div class="james mt-3"><James {limbs} {body}/></div>
    </div>
</div>

